@config "../../tailwind.landing.config.js";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

a[href],
input[type='checkbox'],
input[type='submit'],
input[type='image'],
input[type='radio'],
label[for],
select,
button {
  cursor: pointer;
}

button .disabled {
  display: none;
}

button[disabled] .disabled {
  display: initial;
}

button .enabled {
  display: initial;
}

button[disabled] .enabled {
  display: none;
}

select:required:invalid {
  color: gray !important;
}

.btn {
  @apply no-animation;
}

.btn-primary {
  @apply text-base-100;
}

.btn-primary.btn-outline:hover {
  @apply text-base-100;
}

.base-button {
  @apply btn btn-primary text-base font-medium;
}

.gray-button {
  @apply btn text-base font-medium;
}

.base-input {
  @apply input input-bordered bg-white;
}
.divider {
  &:before,
  &:after {
    @apply bg-base-200;
    height: 1px;
  }
}

.link {
  @apply no-underline hover:underline;
}

.menu li > *:not(ul, .menu-title, details, .btn):active {
  @apply bg-primary text-gray-50;
}
